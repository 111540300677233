
import { Component, Prop, Vue } from "vue-property-decorator";
import { HousfyCard, HousfySwiper } from "housfy-ui-lib";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import { ICard } from "@/models/Card";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";

@Component({
  name: "ToolsList",
  components: {
    HousfyCard,
    HousfyLink,
    HousfySwiper,
  },
})
export default class ToolsList extends Vue {
  @Prop({ type: Array, default: () => [] })
  customTools!: (ICard | null)[];

  get tools(): (ICard | null)[] {
    return [
      {
        to: MYHExternalRoutes.valuationTool(),
        tracking: "link_tools_valuation",
        qa: "toolButton-valuateAProperty",
        text: this.$mqm
          ? "common.valuateALineBreakProperty"
          : "common.valuateAProperty",
      },
      {
        to: MYHExternalRoutes.simulatorTool(),
        tracking: "link_tools_simulator",
        qa: "toolButton-simulateMortgage",
        text: this.$mqm
          ? "common.simulateLineBreakMortgage"
          : "common.simulateMortgage",
      },
      null,
      null,
    ];
  }

  get toolsList(): (ICard | null)[] {
    return this.customTools.length ? this.customTools : this.tools;
  }
}
